import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

import styled from 'styled-components';

import Header from "../components/header";
import Hero from '../components/Hero';
import SEO from "../components/seo";
import { keywords } from '../constants/Keywords';


const ThankYou = ({ data, location }) => {
  const sources = [
      {
        ...data.desktopHero.childImageSharp.fluid,
        media: `(min-width: 600px)`
      },
      data.mobileHero.childImageSharp.fluid,
  ]
  return (
    <React.Fragment>
      <SEO title="Thank You!" keywords={keywords} />
      <Header path={location.pathname} />
      <Hero sources={sources}>
        <TitleGroup>
          <StyledTitle>Thank You</StyledTitle>
          <StyledTitle>for your</StyledTitle> 
          <StyledTitle>purchase!</StyledTitle>
        </TitleGroup>
      </Hero>
    </React.Fragment>
  )
}

export const data = graphql`
  query {
    desktopHero: file(relativePath: { eq: "hero8_desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
          aspectRatio
        }
      }
    }
    mobileHero: file(relativePath: { eq: "hero8_mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 599, quality: 100) {
          ...GatsbyImageSharpFluid
          aspectRatio
        }
      }
    }
  }
`

const TitleGroup = styled.div`
  h1 {
    padding-right: .75rem;
  }
  h1:last-child {
    padding-right: 0;
  }
`

const StyledTitle = styled.h1`
  color: white;
  display: inline;
  font-weight: 400;
  @media (max-width: 699px) {
    font-size: 2rem;
  }
  @media (max-width: 599px) {
    display: block;
    padding-right: 0;
    text-align: center;
    font-size: 3rem;
  }
`

export default ThankYou;